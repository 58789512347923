exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-components-about-about-header-js": () => import("./../../../src/pages/components/about/about-header.js" /* webpackChunkName: "component---src-pages-components-about-about-header-js" */),
  "component---src-pages-components-about-business-solutions-js": () => import("./../../../src/pages/components/about/business-solutions.js" /* webpackChunkName: "component---src-pages-components-about-business-solutions-js" */),
  "component---src-pages-components-about-infenox-difference-js": () => import("./../../../src/pages/components/about/infenox-difference.js" /* webpackChunkName: "component---src-pages-components-about-infenox-difference-js" */),
  "component---src-pages-components-about-leadership-team-js": () => import("./../../../src/pages/components/about/leadership-team.js" /* webpackChunkName: "component---src-pages-components-about-leadership-team-js" */),
  "component---src-pages-components-careers-accordion-component-js": () => import("./../../../src/pages/components/careers/accordionComponent.js" /* webpackChunkName: "component---src-pages-components-careers-accordion-component-js" */),
  "component---src-pages-components-careers-why-infenox-js": () => import("./../../../src/pages/components/careers/whyInfenox.js" /* webpackChunkName: "component---src-pages-components-careers-why-infenox-js" */),
  "component---src-pages-components-contact-contact-form-js": () => import("./../../../src/pages/components/contact/contact-form.js" /* webpackChunkName: "component---src-pages-components-contact-contact-form-js" */),
  "component---src-pages-components-contact-contact-header-js": () => import("./../../../src/pages/components/contact/contact-header.js" /* webpackChunkName: "component---src-pages-components-contact-contact-header-js" */),
  "component---src-pages-components-contact-contact-submission-js": () => import("./../../../src/pages/components/contact/contact-submission.js" /* webpackChunkName: "component---src-pages-components-contact-contact-submission-js" */),
  "component---src-pages-components-home-about-us-js": () => import("./../../../src/pages/components/home/about-us.js" /* webpackChunkName: "component---src-pages-components-home-about-us-js" */),
  "component---src-pages-components-home-blog-js": () => import("./../../../src/pages/components/home/blog.js" /* webpackChunkName: "component---src-pages-components-home-blog-js" */),
  "component---src-pages-components-home-business-solutions-js": () => import("./../../../src/pages/components/home/business-solutions.js" /* webpackChunkName: "component---src-pages-components-home-business-solutions-js" */),
  "component---src-pages-components-home-header-js": () => import("./../../../src/pages/components/home/header.js" /* webpackChunkName: "component---src-pages-components-home-header-js" */),
  "component---src-pages-components-home-our-services-js": () => import("./../../../src/pages/components/home/our-services.js" /* webpackChunkName: "component---src-pages-components-home-our-services-js" */),
  "component---src-pages-components-partnership-our-partners-js": () => import("./../../../src/pages/components/partnership/our-partners.js" /* webpackChunkName: "component---src-pages-components-partnership-our-partners-js" */),
  "component---src-pages-components-solutions-execution-methodology-js": () => import("./../../../src/pages/components/solutions/execution-methodology.js" /* webpackChunkName: "component---src-pages-components-solutions-execution-methodology-js" */),
  "component---src-pages-components-solutions-industries-catered-js": () => import("./../../../src/pages/components/solutions/industries-catered.js" /* webpackChunkName: "component---src-pages-components-solutions-industries-catered-js" */),
  "component---src-pages-components-solutions-our-engagement-model-js": () => import("./../../../src/pages/components/solutions/our-engagement-model.js" /* webpackChunkName: "component---src-pages-components-solutions-our-engagement-model-js" */),
  "component---src-pages-components-solutions-pillars-js": () => import("./../../../src/pages/components/solutions/pillars.js" /* webpackChunkName: "component---src-pages-components-solutions-pillars-js" */),
  "component---src-pages-components-solutions-services-tab-js": () => import("./../../../src/pages/components/solutions/services-tab.js" /* webpackChunkName: "component---src-pages-components-solutions-services-tab-js" */),
  "component---src-pages-components-solutions-solutions-header-js": () => import("./../../../src/pages/components/solutions/solutions-header.js" /* webpackChunkName: "component---src-pages-components-solutions-solutions-header-js" */),
  "component---src-pages-components-solutions-solutions-tab-js": () => import("./../../../src/pages/components/solutions/solutions-tab.js" /* webpackChunkName: "component---src-pages-components-solutions-solutions-tab-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

